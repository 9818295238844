'use client'

import { ErrorView } from '@/_components/ErrorView'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  return <ErrorView error={error} />
}
